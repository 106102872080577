import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEnvelope, FaTimes } from 'react-icons/fa';

const emailProviders = [
  {
    name: 'Gmail',
    logo: '/email-logos/gmail.png',
    webUrl: (to: string, subject: string, body: string) => 
      `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}`,
    mobileUrl: (to: string, subject: string, body: string) => 
      `googlegmail:///co?to=${to}&subject=${subject}&body=${body}`
  },
  {
    name: 'Outlook',
    logo: '/email-logos/outlook.png',
    webUrl: (to: string, subject: string, body: string) => 
      `https://outlook.live.com/mail/0/deeplink/compose?to=${to}&subject=${subject}&body=${body}`,
    mobileUrl: (to: string, subject: string, body: string) => 
      `ms-outlook://compose?to=${to}&subject=${subject}&body=${body}`
  },
  {
    name: 'Yahoo Mail',
    logo: '/email-logos/yahoo.png',
    webUrl: (to: string, subject: string, body: string) => 
      `https://compose.mail.yahoo.com/?to=${to}&subject=${subject}&body=${body}`,
    mobileUrl: (to: string, subject: string, body: string) => 
      `ymail://mail/compose?to=${to}&subject=${subject}&body=${body}`
  },
  {
    name: 'AOL Mail',
    logo: '/email-logos/aol.png',
    webUrl: (to: string, subject: string, body: string) => 
      `https://mail.aol.com/webmail-std/en-us/compose?to=${to}&subject=${subject}&body=${body}`,
    mobileUrl: (to: string, subject: string, body: string) => 
      `mailto:${to}?subject=${subject}&body=${body}`
  }
];

const staff = [
    { name: 'Kimberly Pakech', position: 'CEO', position2: 'LCSW, LCADC, LICSW, SAP Qualified', summary: 
        `Kimberly Pakech, the CEO of Paragon Wellness Center, brings a wealth of life experiences and an unwavering commitment to education. 
        She has achieved remarkable milestones, including graduating from Temple University with a Bachelor's Degree in Social Work. 
        Guided by a supportive mentor, Kimberly went on to earn a Master's Degree in Social Service and a second Master's Degree in Law and Social Policy. 
        While these accomplishments are deeply meaningful, they are not the most treasured aspects of her journey. 
        For over 30 years, including 27 years of frontline practice in mental health and substance use disorder treatment, Kimberly has embraced the gift of enthusiasm. 
        This unwavering enthusiasm fuels her ability to form genuine connections with individuals during their most vulnerable moments, playing a small but meaningful role in their transformation. 
        To sit with someone, hold space for them, witness peace wash over them, and share in their joy is a privilege she holds close to her heart. 
        It is this deep sense of purpose that keeps Kimberly invested in daily self-reflection and growth, ensuring she has what is needed to continue reaching outward. 
        She is honored to support others along the narrow road worth traveling, offering care, guidance, and hope at every step.`,
        email: 'kimberly.pakech@paragonwellness.org', imageUrl: '/staff-images/kimberly_pakech.png', link: '' },
    // { name: 'Ashley Meehan', position: 'CFO', position2: '(Chief Financial Officer)', summary: '', email: 'ashley.meehan@paragonwellness.org', imageUrl: '/staff-images/Unknown_person.jpg' },
    { name: 'Matthew Darabasz', position: 'CTO', position2: '(Chief Technology Officer)', summary: `As the Chief Technology Officer at Paragon Wellness, Matthew Darabasz spearheads the company's technical operations, ensuring the seamless integration of advanced software solutions and the creation of a secure digital environment. With a steadfast commitment to innovation and cybersecurity, Matthew works diligently to safeguard sensitive data while optimizing systems to support employees and patients. His expertise in implementing and managing technology empowers Paragon Wellness to remain at the forefront of healthcare technology, fostering efficiency, safety, and growth across all levels of the organization.`, email: 'matthew.darabasz@paragonwellness.org', imageUrl: '/staff-images/matt_darabasz.jpg' },
    { name: 'Claire Keller', position: 'Office Manager', position2: '', summary: "Claire Keller brings 35 years of personal experience in long-term recovery from substance use to her work at Paragon Wellness. Deeply rooted in the recovery community, Claire exemplifies that recovery is possible with the right support and engagement. Her lived experience offers invaluable guidance to individuals new to treatment, emphasizing compassionate care, consistent follow-up, and the power of peer support. As a trusted first point of contact for those seeking help, Claire's unwavering dedication to sobriety and her ability to inspire others make her an essential part of our team.", email: 'claire.keller@paragonwellness.org', imageUrl: '/staff-images/claire_keller.jpg'},
    { name: 'Dawn Pakech', position: 'Dedicated EAP Account Manager', position2: 'MSW, LSW', summary: `Dawn Pakech brings nearly 30 years of extensive social work experience, primarily in medical settings, to her role as the Dedicated EAP Account Manager at Paragon Wellness. Throughout her career, Dawn has worked in diverse fields, including women’s health, HIV/AIDS, mental health, and organ failure. Dawn has cultivated a broad range of skills, enabling her to support clients facing complex physical and emotional challenges. She has served as an advocate, case manager, and therapist, consistently focused on improving her clients’ quality of life. Advocacy, in particular, has been a cornerstone of her professional journey. Her extensive experience includes serving as the Ombudsperson in Philadelphia, working with individuals living with HIV/AIDS at ActionAIDS, and assisting clients navigating organ failure and transplant processes. In each role, Dawn has demonstrated unwavering dedication to meeting the unique needs of varied communities with respect, kindness, and compassion. Dawn is excited to bring her expertise and empathetic approach to her position at Paragon Wellness, continuing her mission to support and empower individuals through personalized care and advocacy.`, email: 'dawn.pakech@paragonwellness.org', imageUrl: '/staff-images/dawn_pakech.png' },
    { name: 'Theresa McCaul', position: 'Clinical Supervisor', position2: 'LMFT', summary: 'Theresa McCaul, LMFT, holds a Masters degree in Marriage and Family Therapy from La Salle University and brings over a decade of clinical expertise to Paragon Wellness. Specializing in holistic care, Theresa works with individuals, couples, families, and groups to foster growth and healing. In addition to her therapeutic practice, she plays a vital role in developing clinical programs and providing mentorship and supervision to clinical staff. Her dedication to enhancing care and supporting her colleagues underscores her value as an integral member of our team.', email: 'theresa.mccaul@paragonwellness.org', imageUrl: '/staff-images/theresa_mccaul.jpg' },
    { name: 'Tanisha Hutchins', position: 'Admissions Coordinator', position2: '', summary: 'Tanisha Hutchins serves as the Admissions Coordinator at Paragon Wellness Center, bringing a wealth of experience and a compassionate touch to the treatment space. With a deep understanding of the courage it takes to seek help, Tanisha is dedicated to guiding individuals through their journey toward healing. Her approach is rooted in empathy and genuine care, ensuring that every person she works with feels seen, heard, and supported. By connecting vulnerable individuals with quality resources and services, Tanisha plays a pivotal role in fostering hope and facilitating transformative change in the lives of those she serves.', email: 'tanisha.hutchins@paragonwellness.org', imageUrl: '/staff-images/tanisha_hutchins.png' },
    { name: 'Tina M. Wright', position: 'Therapist', position2: 'MA, CADC', summary: "With over 20 years of experience in the behavioral health field, Tina Wright is a highly skilled therapist specializing in substance abuse treatment, family crisis intervention, and dual diagnosis care. Her compassionate approach fosters a safe and supportive environment, empowering individuals to navigate the challenges of co-occurring disorders and achieve meaningful growth.", email: 'tina.wright@paragonwellness.org', imageUrl: '/staff-images/tina_wright.jpg' },
    { name: 'Mark Ryan', position: 'Therapist', position2: 'MS, LAC', summary: 'Mark Ryan has over five years of experience in the field of addiction treatment. Known for his calm and welcoming demeanor, Mark creates a safe and supportive environment for clients. His exceptional ability to build strong rapport helps individuals feel understood and motivated as they work toward their goals. Committed to fostering meaningful change, Mark empowers clients to cultivate a more balanced and fulfilling life.', email: 'mark.ryan@paragonwellness.org', imageUrl: '/staff-images/mark_ryan.jpg' },
    { name: 'Virginia Vitella', position: 'Therapist', position2: 'MSW', summary: `Virginia Vitella is a dedicated therapist who employs evidence-based therapeutic approaches such as Motivational Interviewing, Cognitive Behavioral Therapy, Relapse Prevention, Psychodynamics, Mindfulness-Based Interventions, and Dialectical Behavior Therapy. Her deep understanding of these modalities allows her to craft personalized treatment plans tailored to the unique needs of each individual she works with. Virginia also provides a safe and affirming space for individuals who identify with the LGBTQ+ community, offering compassionate and understanding support as they navigate their unique experiences. With a blend of empathy, expertise, and a genuine commitment to helping others thrive, Virginia is dedicated to making a meaningful difference in the lives of those she serves.`, email: 'virginia.vitella@paragonwellness.org', imageUrl: '/staff-images/virginia_vitella.png' },
    { name: 'Roxanne Logan', position: 'Therapist', position2: 'JD, MSS, LCSW', summary: "Roxanne Logan holds advanced degrees in law and social work, bringing over 16 years of expertise in the Philadelphia Child Welfare System to her role at Paragon Wellness. Driven by a deep passion for social justice, Roxanne aligns her practice with our mission to deliver safe, ethical, and judicious care. Throughout her career, she has dedicated herself to improving the lives of children and families in crisis through counseling, education, and advocacy. Roxanne's ability to engage with diverse populations and create lasting positive change makes her an invaluable member of our clinical team.", email: 'roxanne.logan@paragonwellness.org', imageUrl: '/staff-images/roxanne_logan.jpg' },
    // { name: 'Allison Lagera', position: 'Administrative Assistant', position2: '', summary: '', email: 'allison.lagera@paragonwellness.org', imageUrl: '/staff-images/allison_lagera.png' },
];

const EmployeeDetail: React.FC = () => {
  const { employeeName } = useParams<{ employeeName: string }>();
  const navigate = useNavigate();
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const employee = staff.find(member => member.name.replace(/\s+/g, '-').toLowerCase() === employeeName);

  if (!employee) {
    return <div>Employee not found</div>;
  }

  const handleEmailClick = (provider: typeof emailProviders[0]) => {
    const subject = encodeURIComponent(`Inquiry for ${employee.name}`);
    const body = encodeURIComponent(`Hello ${employee.name},\n\n`);
    
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    if (isMobile) {
      // Try mobile app first
      const mobileWindow = window.open(provider.mobileUrl(employee.email, subject, body), '_blank');
      
      // If mobile app fails or times out, fall back to web version
      setTimeout(() => {
        if (mobileWindow === null || mobileWindow.closed) {
          window.open(provider.webUrl(employee.email, subject, body), '_blank');
        }
      }, 1000);
    } else {
      // On desktop, use web version
      window.open(provider.webUrl(employee.email, subject, body), '_blank');
    }
    
    setShowEmailModal(false);
  };

  return (
    <>
      <section className="py-20 min-h-screen mt-16 sm:mt-0 bg-gradient-to-b from-white via-gray-50 to-white">
        <div className="container mx-auto px-4 lg:px-8">
          <button
            onClick={() => navigate(-1)}
            className="group flex items-center text-customTextColor hover:text-burntOrange transition-colors duration-300 mb-8"
          >
            <FaArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-300" />
            <span className="font-medium">Back to Team</span>
          </button>

          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            {/* Header Section with Background and Logo */}
            <div className="relative h-48 bg-gradient-to-r from-burntOrange to-oliveGreen">
              {/* Company Logo */}
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[400px] px-4 sm:px-0">
                <img 
                  src="/logos/fullLogo.png" 
                  alt="Paragon Wellness Logo" 
                  className="w-full h-auto min-h-[80px] object-contain opacity-90"
                />
              </div>
              {/* Employee Image */}
              <div className="absolute -bottom-20 left-8 md:left-12">
                <div className="relative">
                  <div className="w-40 h-40 rounded-full border-4 border-white overflow-hidden shadow-lg">
                    <img
                      src={employee.imageUrl}
                      alt={employee.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="pt-24 px-8 md:px-12 pb-12">
              <div className="flex flex-col md:flex-row md:items-start md:justify-between mb-8">
                <div>
                  <div className="mb-4">
                    <h1 className="text-3xl font-bold text-customTextColor">
                      {employee.name}
                      {employee.position2 && (
                        <span className="text-xl font-medium text-burntOrange ml-2">
                          {employee.position2}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div className="space-y-2">
                    <p className="text-xl text-gray-700 font-medium">
                      {employee.position}
                    </p>
                    <p className="text-gray-600">
                      {employee.email}
                    </p>
                  </div>
                </div>
                
                <div className="mt-4 md:mt-0 flex gap-3">
                  <button
                    onClick={() => setShowEmailModal(true)}
                    className="group inline-flex items-center justify-center px-6 py-2.5 bg-oliveGreen text-white rounded-md hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
                  >
                    <FaEnvelope className="mr-2 h-4 w-4" />
                    Contact
                  </button>
                  {employee.link && (
                    <a
                      href={employee.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group inline-flex items-center justify-center px-6 py-2.5 bg-burntOrange text-white rounded-md hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
                    >
                      Schedule Appointment
                    </a>
                  )}
                </div>
              </div>

              {employee.summary && (
                <div className="mt-8">
                  <h2 className="text-xl font-semibold text-customTextColor mb-4">
                    About
                  </h2>
                  <div className="prose max-w-none">
                    <p className="text-gray-600 leading-relaxed">
                      {employee.summary}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {showEmailModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setShowEmailModal(false)}></div>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Choose Email Provider
                      </h3>
                      <button
                        onClick={() => setShowEmailModal(false)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <FaTimes className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      {emailProviders.map((provider) => (
                        <button
                          key={provider.name}
                          onClick={() => handleEmailClick(provider)}
                          className="flex flex-col items-center p-4 border rounded-lg hover:bg-gray-50 transition-colors duration-200"
                        >
                          <img
                            src={provider.logo}
                            alt={`${provider.name} logo`}
                            className="w-12 h-12 object-contain mb-2"
                          />
                          <span className="text-sm font-medium text-gray-900">{provider.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeDetail;