import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FaPhoneAlt, FaCalendarAlt, FaInfoCircle, FaUserMd, FaEnvelope, FaGoogle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IconType } from 'react-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';
import BackToTopButton from './BackToTopButton';
import CookieConsent from './CookieConsent';
import { Helmet } from 'react-helmet';
import { GoogleLogin } from '@react-oauth/google';

interface ServiceCardProps {
  serviceId: string;
  imageSrc: string;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ serviceId, imageSrc, title, description, buttonText, onClick }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div ref={cardRef} className="service-card p-6 hover:border-burntOrange transition-all duration-300">
      <div className="flex justify-center mb-4">
        <img src={imageSrc} alt={title} className="w-full h-auto rounded-lg" />
      </div>
      <h3 className="text-xl font-bold text-center mb-2">{title}</h3>
      <p className="text-center mb-4 text-gray-600">{description}</p>
      <div className="cta-button-wrapper">
        <button
          onClick={onClick}
          className="cta-button2 px-6 py-3 bg-burntOrange hover:bg-customTextColor text-white font-bold rounded-full shadow-lg transition-all duration-300"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

interface GoogleReview {
  author_name: string;
  rating: number;
  text: string;
  time: number; // Unix timestamp
  profile_photo_url: string;
}

const ReviewSection: React.FC = () => {
  const [reviews, setReviews] = useState<GoogleReview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/api/reviews');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        // Add minimum rating filter if desired
        const filteredReviews = data.filter((review: GoogleReview) => review.rating >= 3);
        
        setReviews(filteredReviews);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setError('Failed to load reviews');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  // Auto-cycle through reviews
  useEffect(() => {
    if (!isAutoPlaying || reviews.length <= 1) return;

    const interval = setInterval(() => {
      setCurrentReviewIndex((prev) => (prev + 1) % reviews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [reviews.length, isAutoPlaying]);

  const handleLeaveReview = () => {
    window.open(`https://search.google.com/local/writereview?placeid=ChIJ2flZpQjJxokRZB4YVHryubA`, '_blank');
  };

  const handlePrevReview = () => {
    setCurrentReviewIndex((prev) => 
      prev === 0 ? reviews.length - 1 : prev - 1
    );
    setIsAutoPlaying(false);
  };

  const handleNextReview = () => {
    setCurrentReviewIndex((prev) => 
      (prev + 1) % reviews.length
    );
    setIsAutoPlaying(false);
  };

  return (
    <section className="bg-gradient-to-br from-customTextColor to-customHoverColor py-20">
      <div className="container mx-auto px-4 max-w-4xl">
        <h2 className="text-4xl font-playfair font-bold text-white text-center mb-12">
          What Our Clients Say
        </h2>
        
        {/* Leave Review Button */}
        <div className="text-center mb-12">
          <button
            onClick={handleLeaveReview}
            className="cta-button1 flex items-center justify-center mx-auto"
          >
            <FaGoogle className="mr-2" />
            Leave a Review on Google
          </button>
        </div>

        {/* Google Reviews Carousel */}
        <div className="relative bg-white rounded-xl p-8 shadow-lg">
          {isLoading ? (
            <div className="text-center py-8">Loading reviews...</div>
          ) : error ? (
            <div className="text-center text-red-500 py-8">{error}</div>
          ) : reviews.length === 0 ? (
            <div className="text-center py-8">No reviews available</div>
          ) : (
            <div className="review-carousel overflow-hidden">
              {reviews.length > 0 && (
                <div 
                  className="transition-transform duration-500 ease-in-out"
                  style={{ transform: `translateX(-${currentReviewIndex * 100}%)` }}
                >
                  <div className="flex">
                    {reviews.map((review, index) => (
                      <div 
                        key={index}
                        className="w-full flex-shrink-0 px-4"
                        style={{ minWidth: '100%' }}
                      >
                        <div className="flex items-center mb-4">
                          <img
                            src={review.profile_photo_url}
                            alt={review.author_name}
                            className="w-12 h-12 rounded-full object-cover mr-4"
                            onError={(e) => {
                              e.currentTarget.src = '/staff-images/Unknown_person.jpg';  // Fallback image
                            }}
                          />
                          <div>
                            <h4 className="text-lg font-semibold text-customTextColor">
                              {review.author_name}
                            </h4>
                            <p className="text-sm text-gray-600">
                              {format(new Date(review.time * 1000), 'MMM d, yyyy')}
                            </p>
                          </div>
                        </div>
                        <div className="flex mb-2">
                          {[...Array(5)].map((_, i) => (
                            <span 
                              key={i} 
                              className={`text-2xl ${
                                i < review.rating ? 'text-burntOrange' : 'text-gray-300'
                              }`}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                        <p className="text-gray-700">{review.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Navigation Buttons */}
          {reviews.length > 1 && (
            <>
              <button
                onClick={handlePrevReview}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white text-customTextColor rounded-full p-2 shadow-lg transition-all duration-300"
                aria-label="Previous review"
              >
                <FaChevronLeft className="w-6 h-6" />
              </button>
              <button
                onClick={handleNextReview}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white text-customTextColor rounded-full p-2 shadow-lg transition-all duration-300"
                aria-label="Next review"
              >
                <FaChevronRight className="w-6 h-6" />
              </button>
            </>
          )}

          {/* Navigation Dots */}
          {reviews.length > 1 && (
            <div className="flex justify-center gap-2 mt-4">
              {reviews.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setCurrentReviewIndex(index);
                    setIsAutoPlaying(false);
                  }}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    currentReviewIndex === index 
                      ? 'bg-burntOrange w-4' 
                      : 'bg-gray-300'
                  }`}
                  aria-label={`Go to review ${index + 1}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const Home: React.FC = () => {
  const navigate = useNavigate();

  const handleServiceClick = (serviceId: string) => {
    navigate(`/services#${serviceId}`);
  };

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo(0, 0);
  };

  const handleEapClick = () => {
    navigate('/eap');
    window.scrollTo(0, 0);
  };

  const handleBlogClick = () => {
    navigate('/blog');
    window.scrollTo(0, 0);
  };

  // Slick slider settings for horizontal scrolling of service cards
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "/home-images/home_1.jpg",
    "/home-images/home_2.jpg",
    "/home-images/home_3.jpg",
    "/home-images/home_4.jpg",
    "/home-images/home_5.jpg",
    "/home-images/home_6.jpg",
    "/home-images/home_7.jpg",
    "/home-images/home_8.jpg",
    "/home-images/home_9.jpg"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === null) {
      setShowCookieConsent(true);
    }
  }, []);

  const handleCookieConsent = (accepted: boolean) => {
    localStorage.setItem('cookieConsent', accepted ? 'accepted' : 'denied');
    setShowCookieConsent(false);
    
    if (accepted) {
      // Enable cookies or tracking here
      console.log('Cookies accepted. Enabling tracking...');
      // For example: initializeTracking();
    } else {
      // Disable cookies or tracking here
      console.log('Cookies denied. Disabling tracking...');
      // For example: disableTracking();
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "MedicalBusiness",
    "name": "Paragon Wellness Center",
    "description": "Comprehensive EAP services, therapy, and mental health care in Philadelphia.",
    "url": "https://www.paragonwellness.org",
    "telephone": "215-792-6040",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "3396 Miller Street",
      "addressLocality": "Philadelphia",
      "addressRegion": "PA",
      "postalCode": "19134",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 39.9926,
      "longitude": -75.1652
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "09:00",
        "closes": "21:00"
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Paragon Wellness Center | EAP Services, Therapy, and Mental Health Care in Philadelphia</title>
        <meta name="description" content="Paragon Wellness Center offers comprehensive EAP services, therapy, and mental health care in Philadelphia. Discover our personalized approach to wellness." />
        <link rel="canonical" href="https://www.paragonwellness.org/home" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" />
      </Helmet>
      
      {/* Hero Section */}
      <section className="hero-section relative h-screen">
        <div className="image-slider-container absolute inset-0">
          {images.map((src, index) => (
            <img
              key={src}
              src={src}
              alt={`Background Banner ${index + 1}`}
              className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
                index === currentImageIndex ? 'opacity-100' : 'opacity-0'
              }`}
            />
          ))}
          <div className="bg-gradient-overlay" />
          
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="text-center max-w-5xl px-4 space-y-8">
              <div className="space-y-4">
                <span className="text-white uppercase tracking-widest text-sm md:text-base font-semibold italic">
                  Your Journey to Wellness Starts Here
                </span>
                <h1 className="hero-title font-playfair text-white">
                  Paragon Wellness Center
                </h1>
                <p className="hero-subtitle font-poppins text-white italic">
                  Committed to providing exceptional care for your overall well-being
                </p>
                <div className="flex justify-center gap-2 mt-4">
                  <span className="inline-block w-2 h-2 bg-white rounded-full animate-pulse"></span>
                  <span className="inline-block w-2 h-2 bg-white rounded-full animate-pulse delay-100"></span>
                  <span className="inline-block w-2 h-2 bg-white rounded-full animate-pulse delay-200"></span>
                </div>
              </div>
              
              <div className="flex flex-col items-center gap-6 mt-12">
                <div className="flex flex-wrap justify-center gap-4">
                  <button 
                    onClick={handleServicesClick} 
                    className="hero-button group bg-burntOrange hover:bg-customTextColor text-white px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    <span className="group-hover:tracking-wider transition-all duration-300">
                      Explore Services
                    </span>
                  </button>
                  <button 
                    onClick={() => navigate('/contact#contact-section')} 
                    className="hero-button-outline group border-2 border-white bg-white/10 backdrop-blur-sm text-white px-8 py-3 rounded-full font-bold hover:bg-white hover:text-customTextColor transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    <span className="group-hover:tracking-wider transition-all duration-300">
                      Get in Touch
                    </span>
                  </button>
                  <button 
                    onClick={() => navigate('/member')} 
                    className="hero-button group bg-oliveGreen hover:bg-customTextColor text-white px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl"
                  >
                    <span className="group-hover:tracking-wider transition-all duration-300">
                      EAP Member Portal
                    </span>
                  </button>
                </div>
                
                <div className="flex flex-wrap justify-center gap-4 mt-4">
                  <button 
                    onClick={() => navigate('/about')} 
                    className="text-white hover:text-customHoverColor transition-colors duration-300 flex items-center gap-2 font-semibold"
                  >
                    <FaInfoCircle className="w-4 h-4" />
                    <span>About Us</span>
                  </button>
                  <span className="text-white/80">|</span>
                  <button 
                    onClick={() => window.location.href = 'tel:215-792-6040'} 
                    className="text-white hover:text-customHoverColor transition-colors duration-300 flex items-center gap-2 font-semibold"
                  >
                    <FaPhoneAlt className="w-4 h-4" />
                    <span>215-792-6040</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-gradient-to-b from-white via-beige to-oliveGreen/5">
        <div className="container mx-auto px-4">
          <h2 className="section-title text-burntOrange italic">Our Comprehensive Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 italic">
            {/* Replace Slider with Grid */}
            <ServiceCard
              serviceId="clinical"
              imageSrc="/home-images/clinical.jpg"
              title="Clinical Services"
              description="Discover our clinical services tailored to enhance your well-being."
              buttonText="Learn More"
              onClick={() => handleServiceClick('clinical')}
            />
            <ServiceCard
              serviceId="eap"
              imageSrc="/service-images/work-life-balance.webp"
              title="Employee Assistance Program"
              description="Learn about our EAP services to help you navigate life's challenges."
              buttonText="Learn More"
              onClick={() => handleEapClick()}
            />
            <ServiceCard
              serviceId="medical"
              imageSrc="/home-images/Medical.jpg"
              title="Medical Services"
              description="Access top-notch medical care tailored to your specific needs."
              buttonText="Learn More"
              onClick={() => handleServiceClick('medical')}
            />
            <ServiceCard
              serviceId="case-management"
              imageSrc="/home-images/management.jpg"
              title=" Clinical Case Management"
              description="Learn about our Case Management Services."
              buttonText="Learn More"
              onClick={() => handleServiceClick('case-management')}
            />
          </div>
        </div>
      </section>

      {/* Features Section - Modern Grid */}
      <section className="features-section bg-gradient-to-br from-customTextColor via-customHoverColor to-oliveGreen/20 text-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="section-title text-center text-white mb-16">
            Why Choose Paragon Wellness Center?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              title="Expert Team"
              description="Our highly qualified professionals are dedicated to your well-being."
            />
            <FeatureCard
              title="Personalized Care"
              description="We tailor our services to meet your unique needs and goals."
            />
            <FeatureCard
              title="Holistic Approach"
              description="We focus on your overall health, addressing both mind and body."
            />
          </div>
        </div>
      </section>

      {/* Reviews Section - Modernized */}
      <section className="reviews-section py-20">
        <div className="container mx-auto px-4">
          <ReviewSection />
        </div>
      </section>

      <BackToTopButton />

      {/* The CookieConsent component will now appear on every reload */}
      {showCookieConsent && (
        <CookieConsent onConsent={handleCookieConsent} />
      )}
    </>
  );
};

// New components
const ServiceLink: React.FC<{ icon: IconType; title: string; description: string; onClick: () => void }> = ({ icon: Icon, title, description, onClick }) => (
  <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer" onClick={onClick}>
    <Icon className="text-4xl text-customTextColor mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const CTAButton: React.FC<{ icon: IconType; text: string; onClick: () => void }> = ({ icon: Icon, text, onClick }) => (
  <button
    onClick={onClick}
    className="cta-button2 px-6 py-3 bg-burntOrange hover:bg-customTextColor text-white font-bold rounded-full shadow-lg transition-all duration-300 flex items-center"
  >
    <Icon className="text-white h-5 w-5 mr-2" />
    {text}
  </button>
);

const FeatureCard: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className="feature-card bg-white/10 backdrop-blur-sm p-6 rounded-lg shadow-md">
    <h3 className="text-xl font-semibold mb-3 text-white">{title}</h3>
    <p className="text-white/90">{description}</p>
  </div>
);

export default Home;