import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BackToTopButton from './BackToTopButton';
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<null | boolean>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { hash } = useLocation(); // Get the hash from the URL

  // Scroll to the section with the corresponding hash when the component loads
  useEffect(() => {
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        const offset = 50; // Adjust the offset value to control how far up it scrolls
        const elementPosition = section.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  const getLocationLink = () => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const googleMapsLink = "https://maps.google.com/?q=3396+Miller+Street,+Philadelphia,+PA+19134";
    const appleMapsLink = "http://maps.apple.com/?q=3396+Miller+Street,+Philadelphia,+PA+19134";

    return isIOS ? appleMapsLink : googleMapsLink;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitSuccess(null);
    setErrorMessage('');

    const nameRegex = /^[a-zA-Z]+$/;
    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      setErrorMessage('Please enter a valid first and last name.');
      setIsSubmitting(false);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      setErrorMessage('Please enter a valid phone number (10 digits).');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: `${firstName} ${lastName}`,
          email,
          phone,
          message: description,
          subject: 'Contact Form Submission'
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        setErrorMessage(result.error || 'An error occurred.');
        setSubmitSuccess(false);
      } else {
        setSubmitSuccess(true);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred.';
      setErrorMessage('An error occurred: ' + errorMessage);
      setSubmitSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Paragon Wellness Center</title>
        <meta name="description" content="Get in touch with Paragon Wellness Center. We're here to support your journey to wellness." />
        <link rel="canonical" href="https://www.paragonwellness.org/contact" />
      </Helmet>
      
      <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20 mt-16 sm:mt-0">
        <div className="container mx-auto px-4 lg:px-8">
          {/* Location Section */}
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12 mb-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center italic">
              Our Location
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Visit us at our Philadelphia location to begin your wellness journey.
            </p>

            <div className="mt-10 flex flex-col justify-center items-center">
              <div className="w-full max-w-4xl overflow-hidden rounded-xl shadow-lg mb-8">
                <iframe
                  width="100%"
                  height="450"
                  frameBorder="0"
                  src="https://www.google.com/maps/embed/v1/place?q=3396+Miller+Street,+Philadelphia,+PA+19134&key=AIzaSyACNUPi5gJox5tj7jip4a-Vyu1ZXRcTv5o"
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                  title="Location Map"
                ></iframe>
              </div>
              <div className="text-xl text-customTextColor font-semibold hover:text-burntOrange transition-colors duration-300">
                <a
                  href={getLocationLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <img src="/locations/locpin.png" alt="Location Pin" className="w-5 h-6 mr-3" />
                  3396 Miller Street, Philadelphia, PA 19134
                </a>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h2 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center italic">
              Contact Us
            </h2>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              We're here to help. Reach out to us with any questions or concerns.
            </p>

            {submitSuccess === true && (
              <div className="bg-oliveGreen/10 text-oliveGreen p-4 rounded-xl text-center mb-8">
                <p className="font-bold text-xl">Form submitted successfully!</p>
              </div>
            )}
            {submitSuccess === false && (
              <div className="bg-burntOrange/10 text-burntOrange p-4 rounded-xl text-center mb-8">
                <p className="font-bold text-xl">{errorMessage}</p>
              </div>
            )}

            {/* Form content remains the same, just updating the button style */}
            <form className="contact-form grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-4xl mx-auto" onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="firstName">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-span-1 md:col-span-2">
                <label className="block text-left mb-2 text-lg font-semibold" htmlFor="description">
                  Reason for Contact (Optional)
                </label>
                <textarea
                  id="description"
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-customTextColor bg-formInput"
                  placeholder="Tell us how we can help you"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group col-span-1 md:col-span-2 text-center">
                <button
                  type="submit"
                  className="bg-burntOrange hover:bg-oliveGreen text-white px-8 py-3 rounded-full font-bold transition-all duration-300 transform hover:-translate-y-1"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Send Message'}
                </button>
              </div>
            </form>

            {/* Additional Contact Information */}
            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Other Ways to Reach Us</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                  <i className="fas fa-phone-alt text-4xl mb-4"></i>
                  <h3 className="text-2xl font-semibold mb-3">Phone</h3>
                  <p><a href="tel:+12157926040" className="hover:text-burntOrange transition-colors duration-300">(215) 792-6040</a></p>
                </div>
                <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                  <i className="fas fa-envelope text-4xl mb-4"></i>
                  <h3 className="text-2xl font-semibold mb-3">Email</h3>
                  <p><a href="mailto:support@paragonwellness.org" className="hover:text-burntOrange transition-colors duration-300">support@paragonwellness.org</a></p>
                </div>
                <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm">
                  <i className="fas fa-map-marker-alt text-4xl mb-4"></i>
                  <h3 className="text-2xl font-semibold mb-3">Address</h3>
                  <p>3396 Miller Street,<br />Philadelphia, PA 19134</p>
                </div>
              </div>
              
              <div className="mt-12 bg-white/10 p-8 rounded-xl backdrop-blur-sm max-w-2xl mx-auto">
                <h3 className="text-2xl font-semibold mb-4">Business Hours</h3>
                <div className="grid grid-cols-2 gap-4 max-w-md mx-auto">
                  <p className="font-medium">Monday - Friday:</p>
                  <p>9:00 AM - 9:00 PM</p>
                  <p className="font-medium">Saturday:</p>
                  <p>Closed</p>
                  <p className="font-medium">Sunday:</p>
                  <p>Closed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

export default Contact;