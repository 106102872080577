import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './About.css';
import BackToTopButton from './BackToTopButton';
import { Helmet } from 'react-helmet';

interface StaffMember {
  name: string;
  position: string;
  position2?: string;
  summary?: string;
  imageUrl: string;
  link?: string;
  category: string | string[];
}

const sortByLastName = (staffMembers: StaffMember[]): StaffMember[] => {
  return staffMembers.sort((a, b) => {
    const lastNameA = a.name.split(' ').pop()?.toLowerCase() || '';
    const lastNameB = b.name.split(' ').pop()?.toLowerCase() || '';
    return lastNameA.localeCompare(lastNameB);
  });
};

const Staff: React.FC = () => {
  const navigate = useNavigate();

  const staff: StaffMember[] = [
    { name: 'Kimberly Pakech', position: 'CEO', position2: 'LCSW, LCADC, LICSW, SAP', summary: '', imageUrl: '/staff-images/kimberly_pakech.png', category: 'executive' },
    // { name: 'Ashley Meehan', position: 'CFO', position2: '(Chief Financial Officer)', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'executive' },
    { name: 'Matthew Darabasz', position: 'CTO', position2: '(Chief Technology Officer)', summary: 'As the Chief Technology Officer at Paragon Wellness, Matthew Darabasz spearheads the company’s technical operations, ensuring the seamless integration of advanced software solutions and the creation of a secure digital environment. With a steadfast commitment to innovation and cybersecurity, Matthew works diligently to safeguard sensitive data while optimizing systems to support employees and patients. His expertise in implementing and managing technology empowers Paragon Wellness to remain at the forefront of healthcare technology, fostering efficiency, safety, and growth across all levels of the organization.', imageUrl: '/staff-images/matt_darabasz.jpg', category: 'executive' },
    { name: 'Claire Keller', position: 'Office Manager', position2: '', summary: "Claire Keller brings 35 years of personal experience in long-term recovery from substance use to her work at Paragon Wellness. Deeply rooted in the recovery community, Claire exemplifies that recovery is possible with the right support and engagement. Her lived experience offers invaluable guidance to individuals new to treatment, emphasizing compassionate care, consistent follow-up, and the power of peer support. As a trusted first point of contact for those seeking help, Claire's unwavering dedication to sobriety and her ability to inspire others make her an essential part of our team.", imageUrl: '/staff-images/claire_keller.jpg', category: 'managers' },
    { name: 'Dawn Pakech', position: 'Dedicated EAP Account Manager', position2: 'MSW, LSW', summary: '', imageUrl: '/staff-images/dawn_pakech.png', category: 'managers' },
    { name: 'Theresa McCaul', position: 'Clinical Supervisor', position2: 'LMFT', summary: 'Theresa McCaul, LMFT, holds a Masters degree in Marriage and Family Therapy from La Salle University and brings over a decade of clinical expertise to Paragon Wellness. Specializing in holistic care, Theresa works with individuals, couples, families, and groups to foster growth and healing. In addition to her therapeutic practice, she plays a vital role in developing clinical programs and providing mentorship and supervision to clinical staff. Her dedication to enhancing care and supporting her colleagues underscores her value as an integral member of our team.', imageUrl: '/staff-images/theresa_mccaul.jpg', category: 'managers' },
    { name: 'Tanisha Hutchins', position: 'Admissions Coordinator', position2: '', summary: '', imageUrl: '/staff-images/tanisha_hutchins.png', category: 'supportspecialist' },
    { name: 'Tina M. Wright', position: 'Therapist', position2: 'MA, CADC', summary: "With over 20 years of experience in the behavioral health field, Tina Wright is a highly skilled therapist specializing in substance abuse treatment, family crisis intervention, and dual diagnosis care. Her compassionate approach fosters a safe and supportive environment, empowering individuals to navigate the challenges of co-occurring disorders and achieve meaningful growth.", imageUrl: '/staff-images/tina_wright.jpg', category: 'provider' },
    { name: 'Mark Ryan', position: 'Therapist', position2: 'MS, LAC', summary: '', imageUrl: '/staff-images/mark_ryan.jpg', category: 'provider' },
    { name: 'Virginia Vitella', position: 'Therapist', position2: 'MSW', summary: '', imageUrl: '/staff-images/virginia_vitella.png', category: 'provider' },
    { name: 'Roxanne Logan', position: 'Therapist', position2: 'JD, MSS, LCSW', summary: "Roxanne Logan holds advanced degrees in law and social work, bringing over 16 years of expertise in the Philadelphia Child Welfare System to her role at Paragon Wellness. Driven by a deep passion for social justice, Roxanne aligns her practice with our mission to deliver safe, ethical, and judicious care. Throughout her career, she has dedicated herself to improving the lives of children and families in crisis through counseling, education, and advocacy. Roxanne's ability to engage with diverse populations and create lasting positive change makes her an invaluable member of our clinical team.", imageUrl: '/staff-images/roxanne_logan.jpg', category: 'provider' },
    // { name: 'Allison Lagera', position: 'Administrative Assistant', position2: '', summary: '', email: 'allison.lagera@paragonwellness.org', imageUrl: '/staff-images/allison_lagera.png', category: 'supportspecialist' },
  ];

  const getStaffByCategory = (category: string) => 
    sortByLastName(staff.filter(member => 
      (Array.isArray(member.category) ? member.category.includes(category) : member.category === category)
    ));

  const executives = getStaffByCategory('executive');
  const mdirector = getStaffByCategory('mdirector');
  const managers = getStaffByCategory('managers');
  const providers = getStaffByCategory('provider');
  const supportSpecialists = getStaffByCategory('supportspecialist');

  // Move these component declarations above sliderSettings
  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-all duration-200 transform hover:scale-110 focus:outline-none"
        style={{ right: '-12px' }}
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6 text-customTextColor" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <button
        onClick={onClick}
        className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-all duration-200 transform hover:scale-110 focus:outline-none"
        style={{ left: '-12px' }}
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-6 w-6 text-customTextColor" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "staff-slider",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        }
      }
    ]
  };

  // Update the CSS for the staff section
  const staffSectionStyles = `
    .staff-slider {
      position: relative;
      padding: 0 20px;
    }

    .staff-slider .slick-slide {
      padding: 10px;
    }

    @media (max-width: 640px) {
      .staff-slider {
        padding: 0 15px;
      }
    }

    .staff-slider .slick-dots {
      bottom: -30px;
    }

    .staff-slider .slick-dots li button:before {
      font-size: 8px;
      color: #4A5568;
    }

    .staff-slider .slick-dots li.slick-active button:before {
      color: #2D3748;
    }
  `;

  // Add the styles to the component
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = staffSectionStyles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const renderStaffCards = (members: StaffMember[], isClickable: boolean) => (
    <Slider {...sliderSettings}>
      {members.map((member, index) => (
        <div key={index} className="p-6">
          {isClickable ? (
            <Link to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}>
              <StaffCard member={member} />
            </Link>
          ) : (
            <StaffCard member={member} />
          )}
          {member.link && (
            <a
              href={member.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block bg-customTextColor text-white font-semibold py-2 px-4 rounded-full hover:bg-customHoverColor"
            >
              Schedule Appointment
            </a>
          )}
        </div>
      ))}
    </Slider>
  );

  const StaffCard: React.FC<{ member: StaffMember }> = ({ member }) => (
    <div className="staff-card-new bg-gray-50 rounded-lg p-6 transform transition-all duration-300 group-hover:scale-105 border border-gray-200 shadow-md hover:shadow-lg">
      <div className="flex flex-col items-center">
        <div className="relative mb-4 w-40 h-40">
          <img 
            src={member.imageUrl} 
            alt={member.name} 
            className="w-full h-full object-cover rounded-full shadow-lg border-4 border-oliveGreen"
          />
        </div>
        <div className="text-center">
          <div className="mb-2">
            <h4 className="text-xl font-bold text-customTextColor">
              {member.name}
            </h4>
            {member.position2 && (
              <p className="text-sm font-medium text-burntOrange">
                {member.position2}
              </p>
            )}
          </div>
          <p className="text-gray-600 font-medium">{member.position}</p>
        </div>
      </div>
    </div>
  );

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo(0, 0);
  };

  return (
    <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20 mt-16 sm:mt-0">
      <Helmet>
        <title>About Paragon Wellness Center</title>
        <meta name="description" content="Learn about Paragon Wellness Center's mission, vision, and dedicated team of mental health professionals in Philadelphia." />
        <link rel="canonical" href="https://www.paragonwellness.org/about" />
      </Helmet>
      <div className="container mx-auto px-4 lg:px-8">
        {/* About Section */}
        <section id="about" className="mb-20">
          <div className="about-content bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center italic">
              About Us
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Dedicated to providing compassionate mental health care and substance use disorder treatment in Philadelphia.
            </p>

            <div className="text-xl space-y-8 text-gray-700">
              <p className="mb-6 md:px-16">
                Paragon Wellness Center, LLC is a dynamic outpatient treatment program located in the heart of Port Richmond at 3396 Miller Street, Philadelphia, PA 19134.
              </p>
              <p className="italic mb-6 md:px-16">
                Our extensive service lines offer those suffering in silence a wide array of treatment options for healing from the loneliness and despair of substance use disorder and 
                co-occurring mental health conditions. Our clinical team will provide compassionate mental, behavioral, and emotional health and wellbeing care including trauma specific 
                treatment for those carrying tremendous stress and heartache from a one-time traumatic event or having the grave misfortune of prolonged exposure to adverse conditions.
              </p>
              <p className="italic mb-6 md:px-16">
                In addition to direct care, Paragon Wellness Center is well-placed to serve as an Employee Assistance Program (EAP) Professional Provider Organization. 
                The goal of our clinical team and supportive professional providers is to cast our net wide to reach as many lives as we possibly can in effort to effect 
                a true connection. We invite each individual to leap with courage, to search deep within, and to consider the opportunity to chart a new path however narrow 
                it may be. We are intent on serving Employers who understand the weight today’s demands have on their employees as they make a vigorous effort to balance work, 
                play, and love every day. 
              </p>
              <p className="italic mb-24 md:px-16">
                Just as a burden shared is a burden lessened, and at times even lifted, our “soul” aim is to stand ready to walk along the road to wellness with anyone willing
                enough to travel with us! 
              </p>
            </div>

            <div className="flex flex-col sm:flex-row justify-center gap-6 mt-12">
              <button 
                onClick={() => navigate('/contact#location-section')} 
                className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300 italic">Find us</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <button
                onClick={handleServicesClick}
                className="group bg-oliveGreen hover:bg-white text-white hover:text-oliveGreen px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300 italic">Our Services</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            <div className="my-16 h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto"></div>

            <div className="grid md:grid-cols-2 gap-12">
              <div className="bg-white rounded-2xl shadow-xl p-8 transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-3xl font-playfair font-bold mb-6 text-burntOrange italic">Mission</h3>
                <p className="text-xl text-gray-700 italic">
                Welcoming everyone who crosses the threshold with a warm smile and an
                outstretched hand. Listening with the third ear to ensure the conveyance of love
                and the opportunity for healing. Offering intentional care in service to the larger
                vision.
                </p>
              </div>
              
              <div className="bg-white rounded-2xl shadow-xl p-8 transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-3xl font-playfair font-bold mb-6 text-oliveGreen italic">Vision</h3>
                <p className="text-xl text-gray-700 italic">
                  To be experienced as a safe place for those who are hurting, where they may find the joy of
                  living through a journey that leads to health and well-being. Impacting the
                  community and its members in a meaningful way, ending the silent suffering that accompanies
                  substance use disorders and mental health conditions. Eradicating shame and fear, one person, one family at a time, with a team of
                  professionals committed to the compassionate art of understanding.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Core Values Section */}
        <section id="core-values" className="mb-20">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h2 className="text-4xl font-playfair font-bold mb-6 text-customTextColor text-center italic">Our Core Values</h2>
            <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-burntOrange italic">Compassion</h3>
                <p className="text-gray-700 italic">We approach every individual with empathy and understanding, recognizing the unique journey of each person we serve.</p>
              </div>
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-oliveGreen italic">Integrity</h3>
                <p className="text-gray-700 italic">We uphold the highest ethical standards in all our interactions, ensuring trust and confidentiality.</p>
              </div>
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-burntOrange italic">Innovation</h3>
                <p className="text-gray-700 italic">We continuously seek new and effective evidence-based approaches to improving outcomes in mental health and substance use disorder treatment.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Staff Section */}
        <section id="staff" className="mb-20">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12 overflow-hidden">
            <h2 className="text-4xl font-playfair font-bold mb-6 text-customTextColor text-center italic">Meet Our Team</h2>
            <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Left Column */}
              <div>
                {/* Executive Leadership */}
                <div className="mb-16">
                  <h3 className="text-2xl font-bold mb-8 text-center text-burntOrange italic">Executive Leadership</h3>
                  <div className="relative px-4">
                    <Slider {...sliderSettings}>
                      {executives
                        .sort((a, b) => {
                          const positions = { 'CEO': 1, 'CFO': 2, 'CTO': 3 };
                          return positions[a.position as keyof typeof positions] - positions[b.position as keyof typeof positions];
                        })
                        .map((member, index) => (
                          <div key={index} className="px-4">
                            <Link 
                              to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}
                              className="group relative block"
                            >
                              <div className="staff-card-new bg-gray-50 rounded-lg p-6 transform transition-all duration-300 group-hover:scale-105 border border-gray-200 shadow-md hover:shadow-lg">
                                <div className="flex flex-col items-center">
                                  <div className="relative mb-4 w-40 h-40">
                                    <img 
                                      src={member.imageUrl} 
                                      alt={member.name} 
                                      className="w-full h-full object-cover rounded-full shadow-lg border-4 border-burntOrange"
                                    />
                                  </div>
                                  <div className="text-center">
                                    <div className="mb-2">
                                      <h4 className="text-xl font-bold text-customTextColor">
                                        {member.name}
                                      </h4>
                                      {member.position2 && (
                                        <p className="text-sm font-medium text-burntOrange">
                                          {member.position2}
                                        </p>
                                      )}
                                    </div>
                                    <p className="text-gray-600 font-medium">{member.position}</p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>

                {/* Clinical Team */}
                <div className="mb-16">
                  <h3 className="text-2xl font-bold mb-8 text-center text-burntOrange italic">Providers</h3>
                  <div className="relative px-4">
                    <Slider {...sliderSettings}>
                      {providers.map((member, index) => (
                        <div key={index} className="px-4">
                          <Link 
                            to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}
                            className="group relative block"
                          >
                            <div className="staff-card-new bg-gray-50 rounded-lg p-6 transform transition-all duration-300 group-hover:scale-105 border border-gray-200 shadow-md hover:shadow-lg">
                              <div className="flex flex-col items-center">
                                <div className="relative mb-4 w-40 h-40">
                                  <img 
                                    src={member.imageUrl} 
                                    alt={member.name} 
                                    className="w-full h-full object-cover rounded-full shadow-lg border-4 border-burntOrange"
                                  />
                                </div>
                                <div className="text-center">
                                  <div className="mb-2">
                                    <h4 className="text-xl font-bold text-customTextColor">
                                      {member.name}
                                    </h4>
                                    {member.position2 && (
                                      <p className="text-sm font-medium text-burntOrange">
                                        {member.position2}
                                      </p>
                                    )}
                                  </div>
                                  <p className="text-gray-600 font-medium">{member.position}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div>
                {/* Management Team */}
                <div className="mb-16">
                  <h3 className="text-2xl font-bold mb-8 text-center text-oliveGreen italic">Management Team</h3>
                  <div className="relative px-4">
                    <Slider {...sliderSettings}>
                      {managers.map((member, index) => (
                        <div key={index} className="px-4">
                          <Link 
                            to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}
                            className="group relative block"
                          >
                            <div className="staff-card-new bg-gray-50 rounded-lg p-6 transform transition-all duration-300 group-hover:scale-105 border border-gray-200 shadow-md hover:shadow-lg">
                              <div className="flex flex-col items-center">
                                <div className="relative mb-4 w-40 h-40">
                                  <img 
                                    src={member.imageUrl} 
                                    alt={member.name} 
                                    className="w-full h-full object-cover rounded-full shadow-lg border-4 border-oliveGreen"
                                  />
                                </div>
                                <div className="text-center">
                                  <div className="mb-2">
                                    <h4 className="text-xl font-bold text-customTextColor">
                                      {member.name}
                                    </h4>
                                    {member.position2 && (
                                      <p className="text-sm font-medium text-burntOrange">
                                        {member.position2}
                                      </p>
                                    )}
                                  </div>
                                  <p className="text-gray-600 font-medium">{member.position}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>

                {/* Support Team */}
                <div>
                  <h3 className="text-2xl font-bold mb-8 text-center text-oliveGreen italic">Support Team</h3>
                  <div className="relative px-4">
                    <Slider {...sliderSettings}>
                      {supportSpecialists.map((member, index) => (
                        <div key={index} className="px-4">
                          <Link 
                            to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}
                            className="group relative block"
                          >
                            <div className="staff-card-new bg-gray-50 rounded-lg p-6 transform transition-all duration-300 group-hover:scale-105 border border-gray-200 shadow-md hover:shadow-lg">
                              <div className="flex flex-col items-center">
                                <div className="relative mb-4 w-40 h-40">
                                  <img 
                                    src={member.imageUrl} 
                                    alt={member.name} 
                                    className="w-full h-full object-cover rounded-full shadow-lg border-4 border-oliveGreen"
                                  />
                                </div>
                                <div className="text-center">
                                  <div className="mb-2">
                                    <h4 className="text-xl font-bold text-customTextColor">
                                      {member.name}
                                    </h4>
                                    {member.position2 && (
                                      <p className="text-sm font-medium text-burntOrange">
                                        {member.position2}
                                      </p>
                                    )}
                                  </div>
                                  <p className="text-gray-600 font-medium">{member.position}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section id="cta">
          <div className="bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
            <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Ready to Start Your Journey?</h2>
            <p className="text-xl mb-8 text-center">Take the first step towards healing and well-being with Paragon Wellness.</p>
            <div className="flex flex-col sm:flex-row justify-center gap-6">
              <button 
                onClick={() => window.open("https://www.tebra.com/care/practice/paragon-wellness-center-llc-795719", "_blank", "noopener,noreferrer")}
                className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300 mr-2">Schedule Appointment</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <button 
                  onClick={() => window.open('https://paragoneap.mylifeexpert.com/', '_blank', 'noopener,noreferrer')}
                  className="group bg-oliveGreen hover:bg-customTextColor text-white px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300 mr-2">EAP Member Portal</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
            </div>
          </div>
        </section>
      </div>
      <BackToTopButton />
    </section>
  );
};

export default Staff;