import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';

interface BannerProps {
  isVisible: boolean;
  bannerRef: React.RefObject<HTMLDivElement>;
}

const Banner: React.FC<BannerProps> = ({ isVisible, bannerRef }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div
      ref={bannerRef}
      className={`${
        isVisible ? 'block' : 'hidden'
      } bg-customTextColor text-white p-4 pl-10 pr-8 
      flex justify-between items-center shadow-lg transition-all duration-300 ease-in-out
      hover:shadow-xl border-b border-white/20`}
    >
      {/* Logo with beige background */}
      <button 
        onClick={() => handleNavigation('/home')} 
        className="flex items-center transform transition-transform duration-300 hover:scale-105
                  bg-paddingBackground rounded-lg px-4 py-2"
      >
        <img 
          src="/logos/fullLogo.png" 
          alt="logo" 
          className="h-16 w-full inline-block mr-2" 
        />
      </button>

      {/* Phone Button */}
      <a
        href="tel:215-792-6040"
        className="flex items-center rounded-full bg-customHoverColor/90 
                 text-white px-6 py-3 font-bold transition-all duration-300
                 hover:bg-customHoverColor hover:scale-105 hover:shadow-lg
                 border border-white/30 group"
      >
        <FaPhoneAlt className="text-white h-4 w-4 mr-3 group-hover:animate-bounce" />
        <span className="group-hover:tracking-wider transition-all duration-300 font-semibold">
          Call Us: (215)-PWC-6040
        </span>
      </a>
    </div>
  );
};

export default Banner;
