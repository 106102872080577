import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BackToTopButton from './BackToTopButton';

const EAP: React.FC = () => {
  const eapServices = [
    {
      title: 'Mental & Emotional Health Support',
      description: 'Confidential counseling for employees dealing with personal issues.',
      imgSrc: 'service-images/mental-health-support.webp',
      link: '/eap/mental-&-emotional-health-support',
    },
    {
      title: 'Substance Use Disorder Treatment',
      description: 'Workshops and resources to help manage stress effectively.',
      imgSrc: 'service-images/substance-use-treatment.webp',
      link: '/eap/substance-use-disorder-treatment',
    },
    {
      title: 'Work-Life Fulfillment Services',
      description: 'Achieve balance and satisfaction in both personal and professional life.',
      imgSrc: 'service-images/work-life-balance.webp',
      link: '/eap/work-life-fulfillment-services',
    },
    {
      title: 'Health & Wellness Program',
      description: 'Comprehensive support for physical and mental well-being.',
      imgSrc: 'service-images/health-wellness.webp',
      link: '/eap/health-&-wellness-program',
    },
    {
      title: 'Family Support Services',
      description: 'Resources and guidance for various family-related challenges.',
      imgSrc: 'service-images/family-support.webp',
      link: '/eap/family-support-services',
    },
    {
      title: 'Training & Education',
      description: 'Continuous learning opportunities for personal and professional growth.',
      imgSrc: 'service-images/training-education.webp',
      link: '/eap/training-&-education',
    },
    {
      title: 'Critical Incident Stress Management',
      description: 'Critical Incident Stress Management Support is Available Upon Request',
      imgSrc: 'service-images/critical-incident-stress-management.webp',
      link: '/eap/critical-incident-stress-management',
    },
  ];

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.eap-card');
    cards.forEach((card) => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Employee Assistance Program (EAP) Services | Paragon Wellness Center</title>
        <meta name="description" content="Comprehensive Employee Assistance Program (EAP) services at Paragon Wellness Center. Support for mental health, work-life balance, and more." />
        <link rel="canonical" href="https://www.paragonwellness.org/eap" />
      </Helmet>
      <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20 mt-16 sm:mt-0">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="eap-content bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center italic">
              Employee Assistance Program (EAP)
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Our comprehensive EAP is designed to support your well-being and help you thrive both personally and professionally.
              Explore our range of confidential services tailored to meet your unique needs.
            </p>
              
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {eapServices.map((service, index) => (
                <Link to={service.link} key={index} className="no-underline transform transition-all duration-300 hover:-translate-y-2">
                  <div
                    className="eap-card rounded-2xl overflow-hidden shadow-lg relative bg-white"
                    style={{
                      backgroundImage: `url(${service.imgSrc})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: '350px',
                    }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                    <div className="absolute bottom-0 left-0 w-full p-6 text-left">
                      <h3 className="text-xl font-bold text-white mb-2">{service.title}</h3>
                      <p className="text-sm text-gray-300">{service.description}</p>
                      <p className="text-sm text-white mt-2 font-bold">Learn more →</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
              
            <div className="mt-16 bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg">
              <h2 className="text-2xl font-playfair font-bold mb-6 text-burntOrange">Why Choose Our EAP?</h2>
              <ul className="space-y-4 text-gray-700">
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-oliveGreen mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Confidential and professional support</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-oliveGreen mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>24/7 access to resources and counseling</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-6 h-6 text-oliveGreen mr-3 mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Tailored programs for diverse needs</span>
                </li>
              </ul>
            </div>

            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Access Our EAP Member Portal</h2>
              <p className="text-xl mb-8 text-center">Log in to your personalized dashboard to access resources, and track your wellness journey.</p>
              <div className="flex justify-center">
                <button 
                  onClick={() => window.open('https://paragoneap.mylifeexpert.com/', '_blank', 'noopener,noreferrer')}
                  className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300 mr-2">EAP Member Portal</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

export default EAP;
